import Sidebarlayout from './Component/Sidebar/Sidebarlayout';
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Headers from './Component/Header/Header';
import { Card } from 'src/components/ui/card';


const AppLayout: React.FC = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  // console.log(sidebarOpen)
  const [open, setOpen] = useState<boolean>(false);

  const { pathname } = useLocation()


  return (

    <div className="w-full flex h-screen overflow-hidden  bg-primaryDarkBlue">
    <div className="w-full flex h-screen overflow-hidden p-2">
      <Sidebarlayout setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen} />
     
      

      <div className="flex flex-1 flex-col w-full overflow-hidden bg-white rounded-md z-9">
        <Headers setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen} />

        <Card className=" w-full flex flex-col rounded-b-md rounded-t-none flex-1  border-none overflow-y-auto bg-[#F1F1F1]">
          <main className=" w-full px-4 pt-4 md:px-6 md:py-2 outlet-container   h-full">
           
           <Outlet />
          </main>
     
          
        </Card>
        <div className="w-full text-right bottom-0   right-0 px-16 py-1 text-gray-500 text-sm bg-[#F1F1F1] rounded-b-md">
                {/* &copy; 2020 Orange Current Technologies Pvt. Ltd. All rights reserved */}
                © 2024 NPCL. All rights reserved
              </div>
      </div>
      </div>
    </div>
  );
};

export default AppLayout
