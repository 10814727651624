import React from 'react';
import { QueryClient, QueryClientProvider } from "react-query";
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { OnlineStatusProvider } from './Modules/Auth/Core/OnlineStatusContext';
import ErrorBoundryMain from './Modules/ErrorBoundry/ErrorBoundryMain';
import { AuthInit } from './Modules/Auth/Core';



function App() {




  // console.log({ state })

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: 5 * 60 * 1000,
      },
    },
  });

  return (
    <>
    <OnlineStatusProvider>
      <AuthInit >
        <QueryClientProvider client={queryClient}>
          <Outlet />
          <ErrorBoundryMain/>
        </QueryClientProvider>

      </AuthInit>
      </OnlineStatusProvider>
    </>
  );
}

export default App;
