import React from 'react'
import { Link } from 'react-router-dom'
import { DerProps } from './columns'

const BlockChainId = ({ transactionId }: DerProps) => {
  const API_URL = process.env.REACT_APP_BASE_URL_BLOCKCHAIN_EXPLORER_URL;
  return (
    <div className='w-full'>
      <Link to={`${API_URL}/?tab=transactions&transId=${transactionId}`} target='_blank' className='underline underline-offset-4 hover:text-text_primaryDarkBlue '>{transactionId?.slice(0, 25)}...</Link>
    </div>
  )
}

export default BlockChainId