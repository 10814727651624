export interface OptionaValueProps{
       label:string,
    value:string
}

export const userFilterModule:OptionaValueProps[]=[{
    label:'Weekly',
    value:'week'
},
{
    label:'Monthly',
    value:'month'
},
{
    label:'Yearly',
    value:'year'
},]