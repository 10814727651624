import { useEffect, useState } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import LoginsRoutes from 'src/Page/LoginsRoutes'







const AuthRoutes = () => {

    return (
        <Routes>
           <Route >
           <Route path="login/*" element={<LoginsRoutes />} />
            {/* <Route path="register/*" element={<ERegitrationRoutePage />} />
            <Route path='forgot-password/*' element={<ForgotRoutePage/>}/> */}
            <Route index element={<LoginsRoutes />} /> 
           
            </Route>
        </Routes>
    )
}

export default AuthRoutes

