import { Route, Routes } from 'react-router-dom'

import React from 'react'

import LoginLayout from './Component/LoginLayout'
import ILoginScreen from 'src/Modules/Auth/Login/Component/ILoginScreen'
import LoginOtp from 'src/Modules/Auth/OTP/LoginOtp'

const LoginsRoutes = () => {
    return (
        <>


            <Routes >
                <Route element={<LoginLayout />}>
                    <Route path="/" element={<ILoginScreen />} />
                    <Route path="/otp" element={<LoginOtp />} />
                </Route>
            </Routes>




        </>
    )
}

export default LoginsRoutes
