import { FC, useState, createContext, useContext, useEffect, useReducer } from 'react'



import { ReactNode, ReactPropTypes } from 'react'
import { QueryRequestContextProps, QueryState, initialQueryRequest, queryStateReducer, queryStateReducersActions } from 'src/helpers'


export type propsWithChildren = {
  children?: ReactNode,
  props?: any,
  type?: string,
  postdata?: any,
  initialQueryparams?: any
}


const QueryRequestContext = createContext<QueryRequestContextProps>(initialQueryRequest)

const QueryRequestProvider: FC<propsWithChildren> = ({ children, initialQueryparams }) => {


  // const [state, setState] = useState<QueryState>({ ...initialQueryRequest.state, ...initialQueryparams })

  const [state, dispatch] = useReducer(queryStateReducer, { ...initialQueryRequest.state, ...initialQueryparams });

  const updateFilter = (updates: Partial<QueryState>) => {
    dispatch({ type: queryStateReducersActions.UPDATE_FILTER, payload: updates })
  }

  const updateF = (updates: Partial<QueryState>) => {
    dispatch({ type: queryStateReducersActions.UPDATE_F, payload: updates })
  }
  const updateQ = (updates: Partial<QueryState>) => {
    dispatch({ type: queryStateReducersActions.UPDATE_Q, payload: updates })
  }
  const updateOther = (updates: Partial<QueryState>) => {
    dispatch({ type: queryStateReducersActions.UPDATE_OTHER, payload: updates })
  }
  const updateState = (updates: Partial<QueryState>) => {
    // const updatedState = { ...state, ...updates } as QueryState
    // setState(updatedState)
  }

  return (
    <QueryRequestContext.Provider value={{ state, updateState, updateFilter, updateF, updateQ, updateOther }}>
      {children}
    </QueryRequestContext.Provider>
  )
}

const useQueryRequest = () => useContext(QueryRequestContext)
export { QueryRequestProvider, useQueryRequest }
