import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { errorReducerState, updateResetError } from 'src/redux/Reducers/errorHadlingSlicer'
import { RootState } from 'src/redux/store'



import { useLocation } from 'react-router-dom'
import { useOnlineStatus } from '../Auth/Core/OnlineStatusContext'
import { ENUM_STATUS_CODE } from 'src/helpers/index'
import { toast, Toaster } from 'sonner'
const ErrorBoundryMain = () => {
    const { errorMessage, isError, isWarning, warningMessage, errorCode, successMessage,isInfo,infoMessage } = useSelector((state: RootState) => errorReducerState(state))
    // const { toast } = useToast();
    const dispatch = useDispatch()
    // const { setCurrentUser, logout } = useAuth()
    const { pathname } = useLocation()
    const { isOnline } = useOnlineStatus()
    // console.log(pathname)
    useEffect(() => {
        if (isError) {
            if (errorMessage) {
                // console.log(errorMessage)
                toast.error(errorMessage)
            }

        }
        else if (isWarning) {
            toast.warning(warningMessage)
        }
        else if (!isOnline) {
            toast.error('You lost your internet connection')
        }else if(isInfo  ){
            if(infoMessage){
                toast.info(infoMessage)
            }
           
        }
        dispatch(updateResetError())

    }, [errorMessage, isError, isWarning, warningMessage, isOnline,isInfo,infoMessage])

    useEffect(() => {
        if (errorCode === ENUM_STATUS_CODE?.AUTHENDICATE) {

            // logout()
        } else if (errorCode === ENUM_STATUS_CODE?.SUCCESS && successMessage !== '' && successMessage !== undefined) {
            toast.success(successMessage)
            // console.log(successMessage)
        } else if (errorCode === ENUM_STATUS_CODE?.CREATE && successMessage !== '' && successMessage !== undefined) {
            toast.success(successMessage)
            // console.log(successMessage)
        }
        dispatch(updateResetError())
    }, [errorCode, successMessage])
    return (
        <div>
            {pathname.includes('auth') ? <Toaster position="top-center" richColors /> : <Toaster position="top-right" richColors />}
            {/* <Toaster position="top-right" richColors /> */}
        </div>
    )
}

export default ErrorBoundryMain
