import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface DerState {
  consumerNo: number | null;
  error: boolean;
  isLoading: boolean;
  message: string | undefined;
  dataChanged: boolean;
}

const initialState: DerState = {
  consumerNo: null,
  dataChanged: false,
  error: false,
  isLoading: false,
  message: "",
};

export const derSlicer = createSlice({
  name: "derSlicer",
  initialState,
  reducers: {
    resetChangedState(state) {
      state.dataChanged = false;
    },
    setError: (state, action: PayloadAction<any>) => {
      state.error = true;
      state.message = action.payload.message;
    },
    resetError: (state) => {
      state.error = false;
      state.message = undefined;
    },
    // updateBlockNumber: (state, action: PayloadAction<any>) => {
    //   state.blockNumber = action.payload;
    //   // state.page = action.payload.pageIndex;
    // },
    updateConsumerNo: (state, action: PayloadAction<any>) => {
      state.consumerNo = action.payload;
      // state.page = action.payload.pageIndex;
    },

  },
});

export const {
  setError,
  resetChangedState,
  resetError,
  updateConsumerNo
} = derSlicer.actions;
export const derReducerState = (state: RootState) => state.der;
export default derSlicer.reducer;
