import { useMemo } from "react";
import { convertToCamelCase } from "../../../../../helpers";

export const CustomBarTooltip = ({ active, payload, label }: any) => {
    // const labelName = useMemo(
    //     () =>
    //         payload[0].payload.label === "week"
    //         ? "Week"
    //         : payload[0].payload.label === "month"
    //         ? "Month"
    //         : payload[0].payload.label === "year"
    //         ? "Year"
    //         : "",
    //     [payload]
    //   );

    // console.log(payload)
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip text-left bg-gray-100 p-2 rounded-md">
                {/* <p className='text-xs font-bold text-[#0D0D12]'>{payload[0].payload.label === undefined ? "" : (`Label: ${payload[0].payload.label}`)}</p> */}
                {/* <p className='text-xs font-bold text-[#026597]'>{payload[0].payload['consumption'] === undefined ? "" : (`Consumption: ${Number(payload[0].payload['consumption'])?.toFixed(2)} kWh`)}</p> */}
                <p className='text-xs font-bold text-[#34C657] '>{payload[0].payload['generation'] === undefined ? "" : (`Generation: ${Number(payload[0].payload['generation'])?.toFixed(2)} kWh`)}</p>
                <p className='text-xs font-bold text-[#F09D0F]'>{payload[0].payload['settlement'] === undefined ? "" : (`Settlement: ${Number(payload[0].payload['settlement'])?.toFixed(2)} kWh`)}</p>
            </div>
        );
    }
    return null;
}

export const RenderLegends = (props:any) => {
    const { payload } = props;
 
    return (
      <div className="flex space-x-4 -mt-4 md:mt-0 justify-end items-center">
        {
          payload.map((entry:any, index:number) => (
            <div key={`item-${index}`} style={{
                color:`${entry?.color}`
            }}
            className="flex space-x-1 items-center text-xs font-Roboto font-medium "
            >
                <div className={`w-2 h-2 md:w-3 md:h-3 rounded-full `} 
                style={{
                    backgroundColor:`${entry?.color}`
                }}></div>
                <div>{convertToCamelCase( entry.value)}</div>
                
                
                </div>
          ))
        }
      </div>
    );
  }