
import axios from "axios";

// import { getAuth, useAuth } from "src/modules/Auth/Core";
import { API_URL,  } from "./apis";
import { getAuth } from "src/Modules/Auth/Core";
// import { useOnlineStatus } from "src/modules/Auth/Core/OnlineStatusContext";
// import { useOnlineStatus } from "src/modules/Auth/Core/OnlineStatusContext";

export const axiosWithToken = axios.create({
    baseURL: API_URL, // Replace with your API base URL
});

export const axiosWithTokenAuth=axios.create({
    baseURL: API_URL, 
})
// You can also set up request interceptors or other configurations
axiosWithToken.interceptors.request.use(
    (config: any) => {
        const token = getAuth();
        // Do something before each request, like adding authentication headers
        config.headers['Authorization'] = `Bearer ${token?.api_token}`;
        return config;
    },
    (error: any) => {
        // Handle request error
        return Promise.reject(error);
    }
);


axiosWithTokenAuth.interceptors.request.use(
    (config: any) => {
        const auth = getAuth();
        // const status=useOnlineStatus()
   
        config.headers['Authorization'] = `Bearer ${auth?.temp_token}`;
 
      
          return config;
        
       
   
    },
    (error: any) => {
      
        return Promise.reject(error);
    }
);