import React, { useEffect } from 'react'
import { OptionaValueProps, userFilterModule } from './userFilterModule'
import { ProsumerDetailsMainState } from '../Context';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { cn } from '../../../../../helpers';

const UserAnyalisisFilter = () => {
    const { state, updateFilter, updateState } = ProsumerDetailsMainState();
    const { consumerNo } = useSelector((state: RootState) => state?.der);

    useEffect(()=>{
        updateFilter && updateFilter({
            consumerNo:consumerNo,
            range:'week'
        })
    },[])
  return (
    <div className='w-full flex justify-between items-center px-4'>
        <div className='text-sm font-bold text-black font-Roboto '>User Trends</div>
        <div className='flex cursor-pointer items-center space-x-2 py-2 '>
{
    userFilterModule.map((cur:OptionaValueProps,index:number)=>{return <div onClick={()=>{
        updateFilter && updateFilter({
            consumerNo:consumerNo,
            range:cur?.value
        })
    }} key={cur?.label} className={cn('text-[12px] text-gray-500   font-semibold border border-grayLable px-4 py-2 rounded-md',{"text-text_primaryDarkBlue bg-gray-200":state?.filter?.range===cur?.value})}>
{
    cur?.label
}
    </div>})
}
</div>
    </div>
  )
}

export default UserAnyalisisFilter