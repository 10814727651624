import React, { useMemo } from "react";
import {
  ProsumerDetailsMainState,
  useQueryResponseData,
  useQueryResponseLoading,
} from "../Context";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { CustomBarTooltip, RenderLegends } from "./CustomeBarchart";
import Loader from "src/components/commons/Loader/Loader";

const UserAnyalysisBarChart = () => {
  const isLoading = useQueryResponseLoading();

  const response: any = useQueryResponseData();
  const { state, updateFilter, updateState } = ProsumerDetailsMainState();
  const data = useMemo(
    () => (isLoading ? [] : response || []),
    [response, isLoading]
  );

  const labelName = useMemo(
    () =>
      state?.filter?.range === "week"
        ? "Weeks"
        : state?.filter?.range === "month"
        ? "Month"
        : state?.filter?.range === "year"
        ? "Years"
        : "",
    [state]
  );

 
  return (
    <div className="w-full h-full">
        {
            isLoading?<div className="w-full h-full -mt-14 justify-center items-center"><Loader/></div>:Array.isArray(data)&&data?.length>0? <ResponsiveContainer width="100%" height={320}>
            <BarChart
              width={500}
              height={300}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              {/* <CartesianGrid strokeDasharray="3 3" /> */}
              <XAxis
                dataKey="label"
                label={{
                  value: labelName,
    
                  offset: 0,
                  position: "insideBottom",
                  style: {
                    textAnchor: "middle",
                    fontSize: "12px",
                    fontWeight: "500",
                    lineHeight: "9.38px",
                  },
                }}
              />
              <YAxis
                label={{
                  value: "Unit (kWh)",
                  angle: -90,
                  position: "insideRight",
                  offset: 50,
                  style: {
                    textAnchor: "middle",
                    fontSize: "12px",
                    fontWeight: "500",
                    lineHeight: "9.38px",
                  },
                }}
              />
              <Tooltip content={<CustomBarTooltip />} />
              <Legend
                verticalAlign="top"
                align="right"
                iconType="circle"
                content={<RenderLegends />}
                height={28}
              />
              <Bar dataKey="settlement" fill="#F09D0F" stackId="a"/>
              <Bar dataKey="generation" fill="#34C657" stackId="a"/>
            </BarChart>
          </ResponsiveContainer>:<div className="w-full text-sm flex justify-center font-Roboto font-medium items-center h-full -mt-14">No data found</div>
        }
     
    </div>
  );
};

export default UserAnyalysisBarChart;
