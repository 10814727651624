export const SSE_URL = process.env.REACT_APP_BASE_URL_SSE;
export const API_URL = process.env.REACT_APP_BASE_URL + "/api/v1";
export const BLOCKCHAIN_API_URL = process.env.REACT_APP_BASE_URL_BLOCKCHAIN;
export const BLOCKCHAIN_API_URL_CLIENT_ORG_2 = process.env.REACT_APP_BASE_URL_BLOCKCHAIN_CLIENT_ORG_2;
export const BLOCKCHAIN_API_URL_CLIENT_ORG_1 = process.env.REACT_APP_BASE_URL_BLOCKCHAIN_CLIENT_ORG_1;
export const AUTH_URL = process.env.REACT_APP_BASE_URL_AUTH + "/api/v1/web";
export const API_URL_SERVICES = process.env.REACT_APP_BASE_URL + "/api/v1";
export const API_URL_META_BASE = process.env.REACT_APP_BASE_URL_META_BASE_URL;
export const API_URL_MAP = process.env.REACT_APP_BASE_URL_MAP;
export const BILL_URL = process.env.REACT_APP_BASE_URL_SERVICES + "/api/v1";
// Dashboard

export const BLOCKCHAIN_DETAIL_API = BLOCKCHAIN_API_URL + "/block/getAll";
export const BLOCKCHAIN_DETAIL_SSE_API = BLOCKCHAIN_API_URL + "/block/events";
export const BLOCKCHAIN_ID_API = BLOCKCHAIN_API_URL + "/transaction/getAll";
export const BLOCKCHAIN_TRANSACTION_DETAILS_API = (id: string) => BLOCKCHAIN_API_URL + "/transaction/get" + "/" + id;
// export const USER_DASHBOARD_SUMMARY = API_URL + '/user/dashboard/summary'
export const USER_DASHBOARD_SUMMARY = API_URL + "/dashboard/summary";

export const EVENT_TIMELINE_SSE = BLOCKCHAIN_API_URL + "/transaction/events";
export const EVENT_TIMELINE_STATTIC = BLOCKCHAIN_API_URL_CLIENT_ORG_1 + "/transaction/getAllByEvent";
export const CONSUMPTIONGENRATIONSETTLEMENT = API_URL + "/dashboard/gencon-chart";
// SSE URL

export const SSE_OTS_NEW_URL = SSE_URL + "/web/ots";

// Testing Verification Report Api

export const TESTING_VERIFICATION_API = API_URL + "/order/fetch-data";

// Drop down
export const URL_GET_ALL_STATE = API_URL + "/state/web/getAll";

export const UPDATE_STATUS = API_URL + "/user/update/status";

// Login Url

export const URL_LOGIN_OTP = API_URL + "/auth/login";
export const URL_LOGIN_OTP_VALIDATE = AUTH_URL + "/auth/login-via-phone-otp-validate";
export const URL_VERIFY_TOKEN = API_URL + "/auth/verify-token";

export const DAILY_TRANSACTION_INVOICE_URL = BILL_URL + "/bills/all-transactions";

// Transaction
export const URL_GET_ALL_TRANSACTIONS = BLOCKCHAIN_API_URL + "/transaction/getAllTransactions";

// map url
export const URL_MAP = API_URL_MAP;

//  drop url
export const URL_USER_LIST = API_URL + "/user/get/userlist";

// gross energy
export const URL_GET_ALL_GROSS_CAPTURE = API_URL + "/gross-energy/fetch-all"

// Der
export const URL_GET_ALL_DER = API_URL + "/user/der"
export const URL_GET_DER_DETAILS = API_URL + "/dashboard/user-analysis"
export const URL_USER_ANYALYSIS_CHART=API_URL+"/dashboard/user-analysis-chart"

// uperc dashbord

export const URL_UPERC_SUMMARY_API=API_URL+'/dashboard/vas-summary'
export const URL_UPERC_LIST_YEAR_REPORT=API_URL+'/dashboard/vas-list'
export const URL_UPERC_LIST_YEAR_REPORT_DOWNLOAD=API_URL+'/dashboard/vas-list-download'
