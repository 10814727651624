import { BarChart3, CalendarCheck, FileClock, FileLock2, FileSpreadsheet, HeartHandshake, History, LandPlot, LayoutDashboard, ListChecks, LogOut, MenuIcon, Milestone, Settings, Sigma, User, Users, Variable } from 'lucide-react'


export interface IMenuItem {
    name: string,
    iconName: any,
    path: string,
    options?: Array<{
        name: string,
        iconName: any,
        path: string,
    }>
}

export const Menu: IMenuItem[] = [
    {
        name: "Dashboard",
        iconName: (className: string) => <LayoutDashboard size={16} className={className} />,
        path: "/dashboard"
    },

    {
        name: "Gross Energy",
        iconName: (className: string) => <LayoutDashboard size={16} className={className} />,
        path: "/gross-energy"
    },

    {
        name: "DER's",
        iconName: (className: string) => <LayoutDashboard size={16} className={className} />,
        path: "/der-list"
    },

    {
        name: "UPERC Dashboard",
        iconName: (className: string) => <LayoutDashboard size={16} className={className} />,
        path: "/uperc-dashboard"
    },

]


export const OtherMenuOption: IMenuItem[] = [
    {
        name: "Prosumer Details",
        iconName: (className: string) => <LayoutDashboard size={16} className={className} />,
        path: "/der-details"
    },
]