import LOtp from './Component/LOtp'
import React from 'react'
import OtpImage from 'src/assets/Image/1231.png'
const LoginOtp = () => {
    return (
        <div className='w-full h-[100%] my-2 space-y-6 md:rounded-xl  md:border md:border-gray-100 md:bg-white lg:flex lg:flex-row-reverse lg:items-center'>
            <div className='w-full flex justify-center items-center '>
                <img src={OtpImage} className='w-3/4 lg:w-3/4' />
            </div>
            <div className='w-full '><LOtp /></div>
        </div>
    )
}

export default LoginOtp