import React from 'react'
import { QueryRequestProvider, QueryResponseProvider } from './Context'
import { Card } from '../../../../components/ui/card'
import UserAnyalisisFilter from './Component/UserAnyalisisFilter'
import UserAnyalysisLineChart from './Component/UserAnyalysisLineChart'
import UserAnyalysisBarChart from './Component/UserAnyalysisBarChart'

type Props = {}

const Main = (props: Props) => {
  return (
    <Card className='w-full h-[380px] mt-4 border border-black/50'>
<UserAnyalisisFilter/>
{/* <UserAnyalysisLineChart/> */}
<div className='w-full h-full'>
<UserAnyalysisBarChart/>
</div>

    </Card>
  )
}

const UserAnalysisChartMain: React.FC<Props> = () => {
  return <>
    <QueryRequestProvider >
      <QueryResponseProvider >
        <Main />
      </QueryResponseProvider>
    </QueryRequestProvider>
  </>
}



export default UserAnalysisChartMain