import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../store"

interface ILoginData {
    phone: string | undefined
    data: any,
    consumerNo: string | undefined,
    type: string | undefined,
    password: string | undefined,
email:string|undefined
}


const initialState: ILoginData = {
    phone: undefined,
    data: {},
    consumerNo: undefined,
    type: undefined,
    password: undefined,
    email:undefined
}


const loginSlicer = createSlice({
    name: 'Login/slice',
    initialState,
    reducers: {
        updateLoginData(state, action: PayloadAction<any>) {
            state.data = action.payload;
            if (action.payload) {
                state.phone = action.payload?.phone
            }
        },
        updatePhoneNumber(state, action: PayloadAction<any>) {
            state.phone = action.payload;
        },
        updateLogin(state, action: PayloadAction<any>) {
            state.phone = action.payload?.phone;
            state.consumerNo = action.payload?.consumerNo;
            state.password = action.payload?.password
            state.type = action.payload?.type
            state.email=action?.payload?.email
        }
    }
})


export const { updateLoginData, updatePhoneNumber, updateLogin } = loginSlicer.actions;

export const loginReducerState = (state: RootState) => state.login;
export default loginSlicer.reducer;