/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useState, useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'


import { useQueryRequest } from './QueryRequestProvider'
import { ReactPropTypes } from 'react';
import { ERROR_CODES, PaginationState, WithChildren, createResponseContext, initialQueryResponse, initialQueryState, stringifyRequestQuery } from 'src/helpers';
import { getAllDerList, getAllGrossCaptureDate } from 'src/services/services';
import { useDispatch } from 'react-redux';
import { updateError, updateErrorCode } from 'src/redux/Reducers/errorHadlingSlicer';


const QueryResponseContext = createResponseContext<any>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({ children, props, type, postdata }) => {

  const { state, updateState } = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  // console.log({state});


  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [updatedQuery])



  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `DER-LIST-${query}`,
    async () => {


      let newFilterState: any = { ...state }
      delete newFilterState["items_per_page"]
      delete newFilterState["page"]

      if (Object.entries(newFilterState).length > 0) {
        const queryString = stringifyRequestQuery(newFilterState)

        return getAllDerList(queryString)
      }

    },
    {
      cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false,
      refetchOnMount: false,
      // refetchOnReconnect: false,
      // retry: false,
      //  staleTime: 5000,
    }
  )

  let responseData: any = useMemo(() => response, [response]);

  let isError = useMemo(
    () => (ERROR_CODES.includes(responseData?.code) ? true : false),
    [responseData]
  );
  let message = useMemo(
    () => (ERROR_CODES.includes(responseData?.code) ? responseData?.message : ""),
    [responseData]
  );
  let errorCode = useMemo(() => responseData?.code, [responseData])
  const dispatch = useDispatch()

  useEffect(() => {
    if (errorCode) {

      dispatch(updateErrorCode(errorCode))

    }
    return () => { };
  }, [errorCode]);

  useEffect(() => {
    if (isError) {

      dispatch(updateError(message))

    }
    return () => { };
  }, [isError, message]);

  return (
    <QueryResponseContext.Provider value={{ isLoading: isFetching, refetch, response, query }}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const { response } = useQueryResponse()
  if (!response) {
    return []
  }

  return response?.data || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const { response }: any = useQueryResponse()

  if (!response || !response?.data?.payload || !response?.data?.payload?.pagination) {
    return defaultPaginationState
  }

  return response?.data?.payload?.pagination
}

const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
