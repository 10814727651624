import axios, { AxiosError, AxiosResponse } from "axios";
import {
  BLOCKCHAIN_DETAIL_API,
  BLOCKCHAIN_ID_API,
  BLOCKCHAIN_TRANSACTION_DETAILS_API,
  CONSUMPTIONGENRATIONSETTLEMENT,
  DAILY_TRANSACTION_INVOICE_URL,
  EVENT_TIMELINE_STATTIC,
  UPDATE_STATUS,
  URL_GET_ALL_DER,
  URL_GET_ALL_GROSS_CAPTURE,
  URL_GET_ALL_TRANSACTIONS,
  URL_GET_DER_DETAILS,
  URL_LOGIN_OTP,
  URL_LOGIN_OTP_VALIDATE,
  URL_UPERC_LIST_YEAR_REPORT,
  URL_UPERC_LIST_YEAR_REPORT_DOWNLOAD,
  URL_UPERC_SUMMARY_API,
  URL_USER_ANYALYSIS_CHART,
  URL_USER_LIST,
  URL_VERIFY_TOKEN,
  USER_DASHBOARD_SUMMARY,
} from "./apis";
import { axiosWithToken } from "./axioseSetup";
import { getAuth } from "src/Modules/Auth/Core";
import { Response } from "../helpers";

// Dashboard api
export function getBlockchainDetails(query: string): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${BLOCKCHAIN_DETAIL_API}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function getBlockchainIdData(query: string): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${BLOCKCHAIN_ID_API}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function getTransactionDetailsApi(id: string): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${BLOCKCHAIN_TRANSACTION_DETAILS_API(id)}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function getUserDashboardSummaryApi(
  query: string
): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${USER_DASHBOARD_SUMMARY}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function getEventTimelineApi(query: string): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${EVENT_TIMELINE_STATTIC}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function getConsumptionGenrationLineChart(
  query: string
): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${CONSUMPTIONGENRATIONSETTLEMENT}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function updateStatusUser(postData: any): Promise<AxiosResponse> {
  return axiosWithToken
    .put(`${UPDATE_STATUS}`, postData)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

// Login API

export const loginApi = (postData: any) => {
  return axiosWithToken
    .post(`${URL_LOGIN_OTP}`, postData)
    .then((d: AxiosResponse<any>) => d?.data)
    .catch((d: AxiosError) => d.response?.data);
};

export const loginOtpValidateApi = (postData: any) => {
  return axiosWithToken
    .post(`${URL_LOGIN_OTP_VALIDATE}`, postData)
    .then((d: AxiosResponse<any>) => d?.data)
    .catch((d: AxiosError) => d.response?.data);
};

export function getUserByToken(): Promise<AxiosResponse> {
  const auth = getAuth();
  return axiosWithToken
    .post(URL_VERIFY_TOKEN)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

// Transactions
export function getAllTransactionsNew(query: string): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${URL_GET_ALL_TRANSACTIONS}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function getDailyTransactionInvoice(
  query?: string
): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${DAILY_TRANSACTION_INVOICE_URL}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

// Drop down url

export function getAlluserListDropDown(): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${URL_USER_LIST}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

// 

export function getAllGrossCaptureDate(): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${URL_GET_ALL_GROSS_CAPTURE}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

// DER
export function getAllDerList(query: string): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${URL_GET_ALL_DER}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function getDerDetails(query: string): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${URL_GET_DER_DETAILS}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function getUserAnyalysisChart(query: string): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${URL_USER_ANYALYSIS_CHART}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

// uperc  dasboard Api

export function getUpercSummary(query: string): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${URL_UPERC_SUMMARY_API}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function getListOfYearSummary(query: string): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${URL_UPERC_LIST_YEAR_REPORT}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function getListOfYearSummaryDownload(query: string): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${URL_UPERC_LIST_YEAR_REPORT_DOWNLOAD}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}