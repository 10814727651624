import React, { useMemo } from 'react'
import { DerMainState, QueryRequestProvider, QueryResponseProvider, columns, useQueryResponseData, useQueryResponseLoading, useQueryResponsePagination } from './Context';
import { Card } from '../../components/ui/card';
import DatatableMain from 'src/components/commons/Table';

type Props = {}

const Main = (props: Props) => {

    const { state, updateFilter, updateState } = DerMainState();
    const isLoading = useQueryResponseLoading();
    const response: any = useQueryResponseData();
    const pagination: any = useQueryResponsePagination();
    const pageIndexChange = (val: any, b: any) => {
        updateFilter &&
            updateFilter({
                items_per_page: b,
                page: val + 1,
            });
    };

    const data = useMemo(
        () =>
            response
                ? response?.data &&
                response?.data.map((cur: any, index: number) => ({
                    sr_no:
                        index +
                        1 +
                        pagination.items_per_page *
                        (pagination.page == 1 ? 0 : pagination.page - 1),
                    ...cur,
                }))
                : [],
        [isLoading, response]
    );

    return (
        <>
            <Card className="w-full bg-white px-4 h-full overflow-y-scroll no-scrollbar">
                <div className="w-full h-full">
                    <DatatableMain
                        pageIndexChange={pageIndexChange}
                        columns={columns}
                        data={data || []}
                        isLoading={isLoading}
                        paggination={pagination}
                        hidePaggination={false}
                    />
                </div>

            </Card>
        </>
    )
}

const DerMain = () => {
    return (
        <QueryRequestProvider>
            <QueryResponseProvider>
                <Main />
            </QueryResponseProvider>
        </QueryRequestProvider>
    );
};
export default DerMain;