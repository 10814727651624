// src/context/OnlineStatusContext.tsx

import React, { createContext, useState, useEffect, ReactNode, useContext } from 'react';

// Define the shape of the context
interface OnlineStatusContextProps {
  isOnline: boolean;
}

const OnlineStatusContext = createContext<OnlineStatusContextProps | undefined>(undefined);

// Create a provider component
export const OnlineStatusProvider = ({ children }: { children: ReactNode }) => {
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);

  useEffect(() => {
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    // Listen to the online status
    window.addEventListener('online', handleStatusChange);
    window.addEventListener('offline', handleStatusChange);

    // Clean up event listeners on unmount
    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    };
  }, []);

  return (
    <OnlineStatusContext.Provider value={{ isOnline }}>
      {children}
    </OnlineStatusContext.Provider>
  );
};

// Create a custom hook for easier access to the context
export const useOnlineStatus = (): OnlineStatusContextProps => {
  const context = useContext(OnlineStatusContext);
  if (context === undefined) {
    throw new Error('useOnlineStatus must be used within an OnlineStatusProvider');
  }
  return context;
};
