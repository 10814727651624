import * as Yup from "yup";


export interface LoginProps {

  email: string | undefined;
  checkvalue: string | undefined;
  password: string | undefined;
  }
  
  // Initial value of form
  export const LoginInitialVal = {

    email: '',
    checkvalue: undefined,
    password: '',
  };
  
  // validation of form
  export const LoginValidation = Yup.object().shape({
  
    // phone: Yup.string()
    // .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits")
    // .matches(/^[0-9]+$/, "Mobile number must contain only digits")
    // .required("Mobile number is required"),
    email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  checkvalue: Yup.string().optional(),
  password: Yup.string()
    .required("Password is required")
    .min(8, 'Password must contain 8 or more characters')
    .max(18, 'Password must be less than or equal to 18 characters')
    .test('lowercase', 'Password must contain at least 1 lowercase letter', value => /[a-z]/.test(value))
    .test('uppercase', 'Password must contain at least 1 uppercase letter', value => /[A-Z]/.test(value))
    .test('number', 'Password must contain at least 1 number', value => /[0-9]/.test(value))
    .test('special', 'Password must contain at least 1 special character', value => /[!@#$%^&*(),.?":{}|<>]/.test(value)),
  });