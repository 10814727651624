import React, { useEffect, useMemo, useState } from "react";
import { Card } from "../../../components/ui/card";
import {
  ENUM_STATUS_CODE,
  checkObjectKeyValue,
  dateForFirstOfMonthAndYesterdayDate,
  getFormatToDateTimeWithoutSecondsTogether,
  stringifyObjectToRequestQuery,
  stringifyRequestQuery,
} from "../../../helpers";
import { useDispatch, useSelector } from "react-redux";
import { getDerDetails } from "src/services/services";
import {
  updateError,
  updateErrorCode,
} from "src/redux/Reducers/errorHadlingSlicer";
import axios, { AxiosError } from "axios";
import dayjs from "dayjs";
import {
  DerMainState,
  QueryRequestProvider,
  QueryResponseProvider,
} from "../Context";
import { RootState } from "src/redux/store";

import UserAnalysisChartMain from "./UserAnyalisis/UserAnalysisChartMain";
import Loader from "src/components/commons/Loader/Loader";

type Props = {};

const Main = (props: Props) => {
  const { consumerNo } = useSelector((state: RootState) => state?.der);
  const { state, updateFilter, updateState } = DerMainState();
  const [loading, setLoading] = useState(false);
  const [apiData, setApiData] = useState<any>();
  const dispatch = useDispatch();
  let dt = dateForFirstOfMonthAndYesterdayDate();

  const summaryApi = async (query: any) => {
    setLoading(true);
    try {
      let apiResp: any = await getDerDetails(query);
      //   dispatch(updateErrorCode(apiResp.code))
      if (apiResp.code === ENUM_STATUS_CODE?.SUCCESS) {
        setApiData(apiResp);
        console.log("success");
      } else {
        dispatch(updateError(apiResp.message));
        dispatch(updateErrorCode(apiResp.code));
        // toast.error(apiResp.message)
      }
      return apiResp.data;
    } catch (err) {
      let error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        dispatch(updateError(error.response?.data.message));
        dispatch(updateErrorCode(error.response?.data.code));
        // toast.error(error.response?.data.message)
      } else {
        dispatch(updateError(error.message));
        //   dispatch(updateErrorCode(error.response?.data.code))
        // toast.error(error.message)
      }
    } finally {
      setLoading(false); // Set loading state to false when request completes (whether success or failure)
    }
  };

  const data = useMemo(() => apiData?.data, [apiData]);

  useEffect(() => {
    let newFilterState: any = {
      startDate: dayjs(new Date(dt?.firstDay)).format("YYYY-MM-DD"),
      endDate: dayjs(new Date(dt?.yesterday)).format("YYYY-MM-DD"),
      consumerNo: consumerNo,
    };

    if (Object.entries(newFilterState).length > 0) {
      const queryString = stringifyObjectToRequestQuery(newFilterState);
      summaryApi(queryString);
    }
    return () => {};
  }, [consumerNo]);

  // console.log(apiData, consumerNo, data)

  const labelMap = {
    consumerCode: "Prosumer Code",
    discom: "Discom",
    plantCode: "Plant Code",
    timestamp: "Registered On",
  };

  const userlabelMap = {
    monthlyGeneratedUnit: "Monthly Generatio",
    soldUnit: "Total Sold to P2P",
    totalGeneration: "Total Generation",
    yesterdayGeneratedUnit: "Yesterday Generation",
  };

  return (
    <>
      <Card className="w-full bg-white p-4 h-full overflow-y-scroll no-scrollbar">
        <div className="text-lg font-bold text-textLightBlack/80 whitespace-nowrap mb-2">Prosumer Details</div>

        {loading ? (
          <Loader />
        ) : (
          <div className="grid grid-cols-2 gap-4">
            {/* First Half */}
            <div className="flex flex-col space-y-2">
              <div className="border text-sm border-black/50 rounded-xl p-4">
                {data &&
                  Object.entries(data?.userDetails)?.map(
                    ([key, value]: any) => (
                      <div className="flex items-center" key={key}>
                        <div className="font-semibold text-text_primaryDarkBlue w-1/3">
                          {labelMap[key as keyof typeof labelMap]} :
                        </div>
                        <div className="w-2/3">
                          {/* {key === "timestamp"
                            ? getFormatToDateTimeWithoutSecondsTogether(value)
                            : value !== null
                            ? value.toString()
                            : "-"} */}

{checkObjectKeyValue && checkObjectKeyValue(value)}
                        </div>
                      </div>
                    )
                  )}
              </div>
            </div>

            <div className="flex flex-col space-y-2">
              <div className="border border-black/50 rounded-xl p-4">
                {data &&
                  Object.entries(data?.summaryDetails)?.map(
                    ([key, value]: any) => (
                      <div className="flex items-center" key={key}>
                        <div className="font-semibold text-sm text-text_primaryDarkBlue w-1/3">
                          {userlabelMap[key as keyof typeof userlabelMap]} :
                        </div>
                        <div className="w-2/3 text-sm">
                          {/* {value ? value?.toFixed(2) : "-"} */}
                          {checkObjectKeyValue && checkObjectKeyValue(value)}
                        </div>
                      </div>
                    )
                  )}
              </div>
            </div>
          </div>
        )}

        <UserAnalysisChartMain />
      </Card>
    </>
  );
};

const ProsumerDetails: React.FC<Props> = () => {
  return (
    // <QueryRequestProvider>
    // <QueryResponseProvider>
    <Main />
    // </QueryResponseProvider>
    // </QueryRequestProvider>
  );
};

export default ProsumerDetails;
