import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { DerProps } from "./columns";
import { DerMainState } from "..";
import { useDispatch } from "react-redux";
import { updateConsumerNo } from "src/redux/Reducers/derSlicer";
import { Badge } from "../../../../components/ui/badge";

const ConsumerNo = ({ consumerNo }: DerProps) => {
  const { state, updateFilter } = DerMainState();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = () => {
    navigate("/der-details");
    dispatch(updateConsumerNo(consumerNo));
  };

  return (
    <>
      <div className="w-full flex justify-center items-center">
        <Badge
          className="w-32 flex cursor-pointer justify-center space-x-3 items-center text-text_primaryDarkBlue bg-white hover:bg-white border border-border_primaryDarkBlue h-8 text-xs font-medium "
          onClick={handleClick}
        >
          View Details
        </Badge>
      </div>
    </>
  );
};

export default ConsumerNo;
