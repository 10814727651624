import React, { useState } from 'react'

import LoginImage from 'src/assets/Image/LoginImg.png'
// import LoginViaPhone from './LoginViaPhone'
import { cn } from 'src/helpers'
import Login from './Login'
// import LoginViaConsumerNo from './LoginViaConsumerNo'


const ILoginScreen = () => {
  
  return (

    <div className='w-full p-4 h-[100%] md:rounded-xl  md:border md:border-gray-100 md:bg-white lg:flex lg:flex-row-reverse lg:items-center lg:justify-center '>
      <div className='p-2 w-full md:flex md:justify-center  '>
        <img src={LoginImage} alt='IMAG' className='md:w-3/4  lg:w-3/4 ' />
      </div>
      <div className='w-full md:mt-8 lg:mt-0'>
        <div className='w-full ml-20 space-y-5'>
          <div className='w-full text-xl font-bold text-text_primaryDarkBlue '>Login</div>
          <div className='w-full flex space-x-4 '>
            {/* <div className={cn('text-grayLable text-sm font-normal cursor-pointer hover:underline hover:underline-offset-2', { "text-text_green_1 underline-[#34C657] underline underline-offset-4": tab === 'phone' })} onClick={() => setTab('phone')}>Phone</div> */}
            {/* <div className={cn('text-grayLable text-sm font-normal cursor-pointer hover:underline hover:underline-offset-2', { "text-text_green_1 underline-[#34C657] underline underline-offset-4": tab === 'consumerNo' })} onClick={() => setTab('consumerNo')}>Consumer Number</div> */}
          </div>
        </div>
        {/* {
          tab === 'phone' ? (<LoginViaPhone />) : (<LoginViaConsumerNo />)
        } */}
        <Login/>

      </div>
    </div>
  )
}

export default ILoginScreen