import { ColumnDef } from "@tanstack/react-table";
import dayjs from "dayjs";
import ViewDetail from "./ViewDetail";
import BlockChainId from "./BlockChainId";
import ConsumerNo from "./ConsumerNo";
import { Badge } from "../../../../components/ui/badge";

export type DerProps = {
  _id: string,
  consumerNo: number,
  contractedCapacity: number,
  status: string,
  userCode: string,
  isActive: boolean,
  transactionId: string,
};

export const columns: ColumnDef<DerProps>[] = [

  {
    accessorKey: "sr_no",
    header: "Sr.No.",

  },
  {
    accessorKey: "consumerNo",
    header: "Consumer No",

  },
  {
    accessorKey: "userCode",
    header: "UserCode",
  },


  {
    accessorKey: "contractedCapacity",
    header: "Capacity",
    cell: ({ row }: any) => {
      let val: number = row.getValue("contractedCapacity");
      return <div className="flex justify-evenly items-start">{Number(val)?.toFixed(2)}</div>;
    },
  },

  {
    accessorKey: "status",
    header: "Status",
    cell: ({ row }: any) => {
      let val: string = row.getValue("status");
      return <>
        {
          val == "active" ? <Badge
            className=" cursor-pointer  text-text_green_1 bg-white hover:bg-white border border-green-400 text-xs "
          >
            Active
          </Badge> : <Badge
            className="cursor-pointer  text-text_primaryPink bg-white hover:bg-white border border-red-400 text-xs "
          >
            In- Active
          </Badge>
        }
      </>;
    },
  },

  {
    accessorKey: "transactionId",
    header: "BcTxId",
    cell({ row: { original } }) {
      return <BlockChainId {...original} />
    },
  },






  {
    id: "updatedAt",
    header: "Action",


    cell({ row: { original } }) {
      return <ConsumerNo {...original} />
    },
  },
];
