import { FC, lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import LayoutMain from "src/Modules/Layout/LayoutMain";
import { WithChildren } from "src/helpers";
import TopBarProgress from "react-topbar-progress-indicator";
import DerMain from "src/Modules/DER/DerMain";
import ProsumerDetails from "src/Modules/DER/Components/ProsumerDetails";
const AppRoutes = () => {
  const DashBoardMain = lazy(
    () => import("src/Modules/Dashboard/DashboardMain/DashBoardMain")
  );
  const BlockchainpageMain = lazy(
    () => import("src/Modules/Dashboard/BlockchainDetails/BlockchainpageMain")
  );
  const ViewMap = lazy(
    () =>
      import(
        "src/Modules/Dashboard/DashboardMain/Component/MapSub/Component/ViewMap"
      )
  );

  const GrossEnergy = lazy(
    () => import("../Modules/GrossCaptureForm/GrossCaptureMain")
  );

  const UpercDashboard=lazy(()=>import("../Modules/UPRCDashboard/UPRCDashboardMain"))

  return (
    <Routes>
      <Route element={<LayoutMain />}>
        <Route path="/*" element={<Navigate to="/dashboard" />} />
        <Route
          path="/dashboard"
          element={
            <SuspensedView>
              <DashBoardMain />
            </SuspensedView>
          }
        />
        <Route
          path="/dashboard-blockchaindetails"
          element={
            <SuspensedView>
              <BlockchainpageMain />
            </SuspensedView>
          }
        />
        <Route
          path="/dashboard-map"
          element={
            <SuspensedView>
              <ViewMap />
            </SuspensedView>
          }
        />

        <Route
          path="/gross-energy"
          element={
            <SuspensedView>
              <GrossEnergy />
            </SuspensedView>
          }
        />

        <Route
          path="/der-list"
          element={
            <SuspensedView>
              <DerMain />
            </SuspensedView>
          }
        />
        <Route
          path="/der-details"
          element={
            <SuspensedView>
              <ProsumerDetails />
            </SuspensedView>
          }
        />
                <Route
          path="/uperc-dashboard"
          element={
            <SuspensedView>
              <UpercDashboard />
            </SuspensedView>
          }
        />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }: any) => {
  // const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      "0": "#831843",
    },
    barThickness: 2,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export default AppRoutes;
